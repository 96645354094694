import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin/layout/admin-pannel/admin.component';
import { LoginComponent } from './admin/login/login.component';
import { IndexComponent } from './index/index.component';

import { BlogPostComponent } from './index/blog-post/blog-post.component';
import { PostsComponent } from './admin/components/posts/posts/posts.component';
import { BlogPostsComponent } from './index/blog-posts/blog-posts.component';
import { ContactComponent } from './admin/components/contact/contact/contact.component';
import { EventsComponent } from './admin/components/events/events/events.component';
import { PhotoByYearsComponent } from './admin/components/photoByYears/photo-by-years/photo-by-years.component';
import { RecommComponent } from './admin/components/recomm/recomm/recomm.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/index',
    pathMatch: 'full',
  },
  {
    path: 'index',
    component: IndexComponent,
  },
  {
    path: 'blog-post/:type/:id',
    component: BlogPostComponent,
  },
  {
    path: 'blog-posts',
    component: BlogPostsComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'posts', pathMatch: 'full' },
      { path: 'posts', component: PostsComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'events', component: EventsComponent },
      { path: 'photoByYears', component: PhotoByYearsComponent },
      { path: 'recomm', component: RecommComponent },
    ],
  },
  { path: '**', redirectTo: '/index' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
