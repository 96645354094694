import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';

import { Post } from '../_models/Post';
import { Contact } from '../_models/Contact';
import { Event } from '../_models/Event';
import { Recomm } from '../_models/Recomm';

import { PostService } from '../_services/posts.service';
import { ContactService } from '../_services/contacts.services';
import { EventService } from '../_services/events.service';
import { PhotoByYearsService } from '../_services/photoByYears';
import { RecommService } from 'src/app/_services/recomms.service';

import { environment } from 'src/environments/environment';
import { PhotoByYear } from '../_models/PhotoByYear';

declare var $: any;
declare var WOW: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
})
export class IndexComponent implements OnInit {
  posts: Post[];
  recomm: Recomm[];
  events: Event[];
  contact: Contact;
  photosByYears;
  displayedPhotos;
  enlargedPhoto: PhotoByYear;

  environment;

  constructor(
    private router: Router,
    private eventService: EventService,
    private postService: PostService,
    private contactService: ContactService,
    private photoByYearsService: PhotoByYearsService,
    private recommService: RecommService,
    public toastr: ToastrManager
  ) {}

  ngOnInit(): void {
    this.environment = environment;
    this.getPosts();
    this.getRecomms();
    this.getPhotoByYears();
    this.getContact();
    this.getEvents();
    this.mainJsInit();
  }

  getRecomms() {
    this.recommService.getRecommsIndex().subscribe(
      (data) => {
        this.recomm = data.reverse();
      },
      (error) => {
        console.log('Pozele nu au putut fi preluate!', error.message);
      }
    );
  }

  getPhotoByYears() {
    this.photoByYearsService.getPhotoByYearsIndex().subscribe(
      (data) => {
        var sortedDateByYear = data.sort((a, b) =>
          a.year > b.year ? -1 : a.year < b.year ? 1 : 0
        );
        this.photosByYears = this.groupBy(
          sortedDateByYear,
          (photo) => photo.year
        );
        this.displayedPhotos = this.photosByYears.entries().next().value[1];
      },
      (error) => {
        console.log('Pozele nu au putut fi preluate!', error.message);
      }
    );
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  getPosts() {
    this.postService.getPostsIndex().subscribe(
      (data) => {
        this.posts = data.reverse();
        this.posts.forEach((element) => {
          // remove html elements
          element.content = element.content.replace(/<(.|\n)*?>/g, ' ');
          // remove new line
          element.content = element.content.replace('&nbsp;', ' ');
          // calculate the days difference between post date and today
          element.daysDifference = Math.round(
            (new Date().getTime() - new Date(element.created_at).getTime()) /
              (1000 * 3600 * 24)
          );
        });
      },
      (error) => {
        console.log(
          'Creațiile studențești nu au putut fi preluate!',
          error.message
        );
      }
    );
  }

  getContact() {
    this.contactService.getContacts().subscribe(
      (data) => {
        this.contact = data[0];
      },
      (error) => {
        console.log('Contactul nu au putut fi preluat!', error.message);
      }
    );
  }

  getEvents() {
    this.eventService.getEventsIndex().subscribe(
      (data) => {
        this.events = data.reverse();
      },
      (error) => {
        console.log('Evenimentele nu au putut fi preluate!', error.message);
      }
    );
  }

  displayPhotosOfYear(selectedYear: String) {
    this.photosByYears.forEach((element, key) => {
      if (key == selectedYear) {
        this.displayedPhotos = element;
      }
    });
  }

  selectPhoto(photo: PhotoByYear) {
    this.enlargedPhoto = photo;
  }

  mainJsInit() {
    // Sticky Nav
    $(window).on('scroll', function () {
      if ($(window).scrollTop() > 50) {
        $('.scrolling-navbar').addClass('top-nav-collapse');
      } else {
        $('.scrolling-navbar').removeClass('top-nav-collapse');
      }
    });

    /* 
   One Page Navigation & wow js
   ========================================================================== */
    //Initiat WOW JS
    new WOW().init();

    // one page navigation
    $('.main-navigation').onePageNav({
      currentClass: 'active',
    });

    $(window).on('load', function () {
      $('body').scrollspy({
        target: '.navbar-collapse',
        offset: 195,
      });

      $(window).on('scroll', function () {
        if ($(window).scrollTop() > 50) {
          $('.fixed-top').addClass('menu-bg');
        } else {
          $('.fixed-top').removeClass('menu-bg');
        }
      });
    });

    // Slick Nav
    $('.mobile-menu').slicknav({
      prependTo: '.navbar-header',
      parentTag: 'span',
      allowParentLinks: true,
      duplicate: false,
      label: '',
    });

    /* 
   Sticky Nav
   ========================================================================== */
    $(window).on('scroll', function () {
      if ($(window).scrollTop() > 50) {
        $('.header-top-area').addClass('menu-bg');
      } else {
        $('.header-top-area').removeClass('menu-bg');
      }
    });

    /* 
   VIDEO POP-UP
   ========================================================================== */
    $('.video-popup').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });

    /* 
   SMOOTH SCROLL
   ========================================================================== */
    var scrollAnimationTime = 1200,
      scrollAnimation = 'easeInOutExpo';

    $('a.scrollto').on('bind', 'click.smoothscroll', function (event) {
      event.preventDefault();
      var target = this.hash;

      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $(target).offset().top,
          },
          scrollAnimationTime,
          scrollAnimation,
          function () {
            window.location.hash = target;
          }
        );
    });

    /* 
   Back Top Link
   ========================================================================== */
    var offset = 200;
    var duration = 500;
    $(window).scroll(function () {
      if ($(this).scrollTop() > offset) {
        $('.back-to-top').fadeIn(duration);
      } else {
        $('.back-to-top').fadeOut(duration);
      }
    });

    $('.back-to-top').on('click', function (event) {
      event.preventDefault();
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        600
      );
      return false;
    });

    /* 
   Come with us
   ========================================================================== */

    $('.comeWithUs').on('click', function (event) {
      event.preventDefault();
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $('#contact').offset().top,
          },
          scrollAnimationTime,
          scrollAnimation
        );
      return false;
    });

    /* stellar js
  ========================================================*/
    $.stellar({
      horizontalScrolling: true,
      verticalOffset: 40,
      responsive: true,
    });

    /* 
   Page Loader
   ========================================================================== */
   setTimeout(function(){ $('#loader').fadeOut(); }, 2000);
    ;
  }

  blogPost(blogID) {
    this.router.navigate(['blog-post/', 1, blogID]);
  }

  showAllPosts() {
    this.router.navigate(['blog-posts']);
  }
}
