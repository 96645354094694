import { Injectable } from '@angular/core';
import { Event } from '../_models/Event';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private http: HttpClient) {}

  getEvents() {
    return this.http.get<Event[]>(environment.URL + 'event');
  }

  getEvent(id: number) {
    return this.http.get<Event>(environment.URL + 'event/' + id);
  }

  deleteEvent(eventID: number) {
    return this.http.delete(environment.URL + 'event/' + eventID);
  }

  editEvent(event: Event) {
    return this.http.put(environment.URL + 'event/' + event.id, event);
  }

  addEvent(event: FormData) {
    return this.http.post(environment.URL + 'event', event);
  }

  //unauthentificated
  getEventsIndex() {
    return this.http.get<Event[]>(environment.URL + 'getEvents');
  }
}
