<footer>
  <div class="container">
    <div class="row">
      <!-- Footer Links -->
      <!-- <div class="col-lg-6 col-sm-6 col-xs-12">
          <ul class="footer-links">
            <li>
              <a href="#">Homepage</a>
            </li>
            <li>
              <a href="#">Services</a>
            </li>
            <li>
              <a href="#">About Us</a>
            </li>
            <li>
              <a href="#">Contact</a>
            </li>
          </ul>
        </div> -->
      <div class="col-lg-12 col-sm-12 col-xs-12">
        <div class="copyright">
          <p>All copyrights reserved &copy; 2021 - Designed by <a rel="nofollow" href="https://uideck.com" target="_blank"> Uideck</a> & Developed by <a rel="nofollow" href="https://adrianiuga.ro" target="_blank">Iuga Adrian-Valentin</a></p>
        </div>
      </div>
    </div>
  </div>
</footer>
