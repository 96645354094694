import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Post } from 'src/app/_models/Post';
import { PostService } from 'src/app/_services/posts.service';

import { AppComponent } from 'src/app/app.component';
import { ToastrManager } from 'ng6-toastr-notifications';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css'],
})
export class PostsComponent implements OnInit {
  AppComponent = new AppComponent(this.router);

  posts: Post[];
  term: string;

  @ViewChild('editModal') private editModal: ElementRef;
  @ViewChild('addModal') private addModal: ElementRef;
  editForm: FormGroup;
  addForm: FormGroup;

  environment;

  itemPerPage = 5;
  curentPage = 1;

  editImagePath: string;
  postContent: any;

  constructor(
    private router: Router,
    private postService: PostService,
    public toastr: ToastrManager,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.AppComponent.checkAuth();
    this.environment = environment;
    this.formsInitializer();
    this.getPosts();
  }

  formsInitializer() {
    this.editForm = this.formBuilder.group({
      id: [],
      created_at: [],
      updated_at: [],
      title: ['', Validators.required],
      author: ['', Validators.required],
      content: ['', Validators.required],
      image: [''],
    });
    this.addForm = this.formBuilder.group({
      id_add: [],
      created_at_add: [],
      updated_at_add: [],
      title_add: ['', Validators.required],
      author_add: ['', Validators.required],
      content_add: ['', Validators.required],
      image_add: ['',  Validators.required],
    });
  }

  getPosts() {
    this.postService.getPosts().subscribe(
      (data) => {
        this.posts = data.reverse();
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Creațiile studențești nu au putut fi preluate! '
        );
      }
    );
  }

  deletePost(postID) {
    this.postService.deletePost(postID).subscribe(
      () => {
        this.getPosts();
        this.toastr.successToastr('Creația a fost ștearsă din listă! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Creația NU a putut fi ștearsă!'
        );
      }
    );
  }

  selectForEdit(post: Post) {
    this.editForm.setValue(post);
    this.editImagePath =  post.image;
  }

  get id() {
    return this.editForm.get('id');
  }

  get title() {
    return this.editForm.get('title');
  }

  get author() {
    return this.editForm.get('author');
  }

  get content() {
    return this.editForm.get('content');
  }

  get image() {
    return this.editForm.get('image');
  }

  get id_add() {
    return this.addForm.get('id_add');
  }

  get title_add() {
    return this.addForm.get('title_add');
  }

  get author_add() {
    return this.addForm.get('author_add');
  }

  get content_add() {
    return this.addForm.get('content_add');
  }

  get image_add() {
    return this.addForm.get('image_add');
  }

  submitEdit() { 
    this.postService.editPost(this.editForm.value).subscribe(
      () => {
        this.getPosts();
        this.editModal.nativeElement.click();
        this.toastr.successToastr('Creația a fost editată cu success! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Creația nu au putut fi modificată! '
        );
      }
    );
  }

  submitAdd() {
    const formData = new FormData();
    formData.append('image', this.addForm.get('image_add').value);
    formData.append('title', this.addForm.get('title_add').value);
    formData.append('author', this.addForm.get('author_add').value);
    formData.append('content', this.addForm.get('content_add').value);

    this.postService.addPost(formData).subscribe(
      () => {
        this.getPosts();
        this.addModal.nativeElement.click();
        this.toastr.successToastr('Creația a fost adăugată cu success! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Creația nu au putut fi adăugată! '
        );
      }
    );
  }

  onFileChanged(event: any) {
    this.addForm.get('image_add').setValue(event.target.files[0]);
  }
}
