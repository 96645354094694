import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.checkAuth();
  }

  checkAuth() {
    if (sessionStorage.getItem('token') !== 'true') {
      this.router.navigate(['']);
    }
  }

}
