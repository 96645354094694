<div id="blog">
  <div class="continer">
    <div class="row">
      <div class="col-12">
        <p class="btn-rm mx-4 mt-4 p-0" (click)="goBack()"><i class="lnr lnr-arrow-left"></i> Înapoi</p>
      </div>
      <div class="col-12 col-md-4" *ngFor="let item of posts">
        <div class="blog-item-wrapper m-4">
          <div class="blog-item-img" style="background: url( {{ environment?.URLImages }}{{ item.image }}); background-size: cover;"></div>
          <div class="blog-item-text">
            <div class="meta-tags">
              <div class="row">
                <div class="col-5">

                  <span class="date"><i class="lnr  lnr-clock"></i> {{ item.daysDifference == 0 ? 'Astăzi' : item.daysDifference == 1 ? 'Ieri': item.daysDifference + ' zile în urmă' }}</span>
                </div>
                <div class="col-7">
                  <span class="date float-right"><i class="lnr lnr-pencil "></i>{{ item.author }}</span>
                </div>
              </div>
            </div>
            <h3>
              <a (click)="blogPost(item.id)">{{ item.title }}</a>
            </h3>
            <p>{{ (item.content.length>250)? (item.content | slice:0:250)+'...':(item.content) }} </p>
            <p (click)="blogPost(item.id)" class="btn-rm">Citește mai mult <i class="lnr lnr-arrow-right"></i></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
