<div class="template">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <h2 class="mobileCenter mobileCenter-left mt-1">Poze pe ani</h2>
            </div>
            <div class="col-xs-12 col-md-6">
                <button class="btn-default-dark mobileCenter mobileCenter-right" data-toggle="modal" data-target="#addModal">
          <i class="fa fa-plus mr-2" aria-hidden="true"></i> Adaugă poză
        </button>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control mt-3 mb-4" [(ngModel)]="term" style="border:1px solid #ced4da;" placeholder="Căutare după ani">
            </div>
            <div class="col-12">
                <div class="table-responsive" *ngIf="photosByYears">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">ID</th>
                                <th scope="col">An</th>
                                <th scope="col">Imagine</th>
                                <th scope="col">Creat</th>
                                <th scope="col">Updatat</th>
                                <th scope="col">Optiuni</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of photosByYears | filter:term | paginate: { itemsPerPage: itemPerPage, currentPage: curentPage }">
                                <td class="align-middle text-center">{{ item.id }}</td>
                                <td class="align-middle text-center">{{ item.year }}</td>
                                <td class="align-middle text-center">
                                    <img src='{{ environment.URLImages }}{{ item.image }}' alt="Imagine Eveniment" width="250px">
                                </td>
                                <td class="align-middle text-center">{{ item.updated_at | date:'medium' }} </td>
                                <td class="align-middle text-center">{{ item.created_at | date:'medium' }}</td>

                                <td class="align-middle text-center options">
                                    <i class="fa fa-edit" (click)="selectForEdit(item)" data-toggle="modal" data-target="#editModal"></i>
                                    <i class="fa fa-trash" (click)="deletePhotoByYear(item.id)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls autoHide="true" class="text-center" (pageChange)="curentPage = $event">
                    </pagination-controls>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- event to edit modal -->

<!-- Modal -->
<div id="editModal" #editModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modifică poză</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="editForm" (ngSubmit)="submitEdit()" class="login100-form validate-form">

                    <div class="wrap-input100 validate-input">
                        <select formControlName="year" class="input100 form-control" [ngClass]="{ 'is-invalid': year.invalid && (year.dirty || year.touched) }" required>
                            <option value="" disabled></option>
                            <option *ngFor="let item of yearsArray" [ngValue]="item">{{item}}</option>
                        </select>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
                        <i class="fa fa-calendar" aria-hidden="true"></i></span>
                    </div>
                    <div class="text-danger" *ngIf="year.invalid && (year.dirty || year.touched )">
                        <div *ngIf="year.errors['required']">Este necesară anul</div>
                    </div>

                    <button [disabled]="editForm.invalid" type="submit" class="btn-default-dark d-block text-center mx-auto mt-4">Trimite</button>

                </form>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Închide</button>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- event to add modal -->

<!-- Modal -->
<div id="addModal" #addModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Adaugă poză</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="addForm" (ngSubmit)="submitAdd()" class="login100-form validate-form">

                    <div class="wrap-input100 validate-input">
                        <select formControlName="year_add" class="input100 form-control" [ngClass]="{ 'is-invalid': year_add.invalid && (year_add.dirty || year_add.touched) }" required>
                            <option value="" disabled></option>
                            <option *ngFor="let item of yearsArray" [ngValue]="item">{{item}}</option>
                        </select>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
                        <i class="fa fa-calendar" aria-hidden="true"></i></span>
                    </div>
                    <div class="text-danger" *ngIf="year_add.invalid && (year_add.dirty || year_add.touched )">
                        <div *ngIf="year_add.errors['required']">Este necesară anul</div>
                    </div>

                    <!-- input imagine creației -->
                    <div class="wrap-input100 validate-input mt-2">
                        <input class="input100 form-control input100TypeFile" type="file" (change)="onFileChanged($event)" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100"><i class="fa fa-picture-o" aria-hidden="true"></i></span>
                    </div>
                    <p style="color:red; margin: 0;">*Nu se pot încărca poze mai mari de 2MB</p>
                    <p style="color:red;">*Înainte de a fi încărcate, pozele trebuie neaparat minifiate cu
                        <a href="https://tinypng.com" target="_blank">Tiny PNG</a></p>

                    <!-- {{ addForm.invalid }} -->
                    <button [disabled]="addForm.invalid" type="submit" class="btn-default-dark d-block text-center mx-auto mt-4">Trimite</button>

                </form>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Închide</button>
                </div>
            </div>

        </div>
    </div>
</div>