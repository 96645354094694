import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Post } from 'src/app/_models/Post';
import { PostService } from 'src/app/_services/posts.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.css'],
})
export class BlogPostComponent implements OnInit {
  environment;
  postID: number;
  post: Post;
  locationOfCalling: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private postService: PostService,
    public toastr: ToastrManager
  ) {}

  ngOnInit(): void {
    this.environment = environment;
    this.getPost();
  }

  getPost() {
    this.route.params.subscribe((params) => {
      this.postID = params.id;
      this.locationOfCalling = params.type;
      this.postService.getPostIndex(this.postID).subscribe(
        (data) => {
          this.post = data;
          this.post.daysDifference = Math.round(
            (new Date().getTime() - new Date(this.post.created_at).getTime()) /
              (1000 * 3600 * 24)
          );
        },
        (error) => {
          this.toastr.errorToastr(
            error.message,
            'Creația nu au putut fi preluată! '
          );
        }
      );
    });
  }

  goBack() {
    if (this.locationOfCalling == '1') {
      this.router.navigate(['index']);
    } else if (this.locationOfCalling == '2') {
      this.router.navigate(['blog-posts']);
    }
  }
}
