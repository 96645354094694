<div class="adminHeader">
    <div class="container-fluid">
        <div class="row pt-3">
            <div class="col-xs-4 col-md-5">
                <div class="logout left-align">
                    <div class="btn btn-info" routerLink="posts"> <i class="fa fa-pencil-square-o pr-2"></i> Creații studențești </div>
                    <div class="btn btn-info" routerLink="contact"> <i class="fa fa-address-book pr-2"></i> Contact </div>
                    <div class="btn btn-info" routerLink="recomm"> <i class="fa fa-address-book pr-2"></i> Recomandări </div>
                </div>
            </div>
            <div class="col-xs-4 col-md-3">
                <h3 class="text-center">OSCER Suceava</h3>
            </div>
            <div class="col-xs-4 col-md-4">
                <div class="logout right-align">
                    <div class="btn btn-info" routerLink="events"> <i class="fa fa-calendar pr-2"></i> Evenimente </div>
                    <div class="btn btn-info" routerLink="photoByYears"> <i class="fa fa-photo pr-2"></i> Poze </div>
                    <div class="btn btn-info" (click)="logout()"> Delogare <i class="fa fa-sign-out pl-1"></i></div>
                </div>
            </div>
        </div>
    </div>
</div>