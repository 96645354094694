import { Injectable } from '@angular/core';
import { Post } from '../_models/Post';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  constructor(private http: HttpClient) {}

  getPosts() {
    return this.http.get<Post[]>(environment.URL + 'post');
  }

  getPost(id: number) {
    return this.http.get<Post>(environment.URL + 'post/' + id);
  }

  deletePost(postID: number) {
    return this.http.delete(environment.URL + 'post/' + postID);
  }

  editPost(post: Post) {
    return this.http.put(environment.URL + 'post/' + post.id, post);
  }

  addPost(post: FormData) {
    return this.http.post(environment.URL + 'post', post);
  }

  //unauthentificated
  getPostsIndex() {
    return this.http.get<Post[]>(environment.URL + 'getPosts');
  }

  getPostIndex(id: number) {
    return this.http.get<Post>(environment.URL + 'getPosts/' + id);
  }
}
