<div class="template">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <h2 class="mobileCenter mobileCenter-left mt-1">Recomandări</h2>
            </div>
            <div class="col-xs-12 col-md-6">
                <button class="btn-default-dark mobileCenter mobileCenter-right" data-toggle="modal"
                    data-target="#addModal">
                    <i class="fa fa-plus mr-2" aria-hidden="true"></i> Adaugă recomandare
                </button>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control mt-3 mb-4" [(ngModel)]="term" style="border:1px solid #ced4da;"
                    placeholder="Căutare">
            </div>
            <div class="col-12">
                <p>Nota informativă -> doar primele 3 recomandări din tabel vor fi afișate pe site!</p>
            </div>
            <div class="col-12">
                <div class="table-responsive" *ngIf="recomms">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">ID</th>
                                <th scope="col">Tipul</th>
                                <th scope="col">Textul</th>
                                <th scope="col">Imagine</th>
                                <th scope="col">Creat</th>
                                <th scope="col">Updatat</th>
                                <th scope="col">Optiuni</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let item of recomms | filter:term | paginate: { itemsPerPage: itemPerPage, currentPage: curentPage }">
                                <td class="align-middle text-center">{{ item.id }}</td>
                                <td class="align-middle text-center">{{ item.type === "recomm" ? "Recomandare" : "Citat" }}</td>
                                <td class="align-middle text-center">{{ (item.text.length>250)? (item.text |
                                    slice:0:250)+'...':(item.text) }}</td>
                                <td class="align-middle text-center">
                                    <div *ngIf="item.type === 'quote'; else elseBlock">
                                        Citatele nu conțin imagini
                                    </div>
                                    <ng-template #elseBlock>
                                        <img src='{{ environment.URLImages }}{{ item.image }}'
                                        alt="Imagine Creații studențești" width="150px">
                                    </ng-template>
                                </td>
                                <td class="align-middle text-center">{{ item.updated_at | date:'medium' }} </td>
                                <td class="align-middle text-center">{{ item.created_at | date:'medium' }}</td>

                                <td class="align-middle text-center options">
                                    <i class="fa fa-edit" (click)="selectForEdit(item)" data-toggle="modal"
                                        data-target="#editModal"></i>
                                    <i class="fa fa-trash" (click)="deletePost(item.id)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls autoHide="true" class="text-center" (pageChange)="curentPage = $event">
                    </pagination-controls>
                </div>
            </div>
        </div>

    </div>
</div>



<!-- post to edit modal -->

<!-- Modal -->
<div id="editModal" #editModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modifică recomandare</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="editForm" (ngSubmit)="submitEdit()" class="login100-form validate-form">

                    <!-- input tipul recomandării -->
                    <div class="wrap-input100 validate-input">
                        <select formControlName="type" class="input100 form-control"
                            [ngClass]="{ 'is-invalid': type.invalid && (type.dirty || type.touched) }" required>
                            <option value="" disabled></option>
                            <option *ngFor="let item of typesArray" [ngValue]="item">{{item}}</option>
                        </select>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
                            <i class="fa fa-file" aria-hidden="true"></i>
                        </span>
                    </div>

                    <!-- input textul recomandării -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="text" class="input100 form-control" type="text"
                            placeholder="Textul recomandării"
                            [ngClass]="{ 'is-invalid': text.invalid && (text.dirty || text.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="text-danger" *ngIf="text.invalid && (text.dirty || text.touched )">
                        <div>Este necesar textul recomandării</div>
                    </div>

                    <button [disabled]="editForm.invalid" type="submit"
                        class="btn-default-dark d-block text-center mx-auto mt-4">
                        Trimite
                    </button>

                </form>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Închide</button>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- post to add modal -->

<!-- Modal -->
<div id="addModal" #addModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Adaugă recomandare</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="addForm" (ngSubmit)="submitAdd()" class="login100-form validate-form">
                    <!-- input tipul recomandării -->
                    <div class="wrap-input100 validate-input">
                        <select formControlName="type_add" class="input100 form-control"
                            [ngClass]="{ 'is-invalid': type_add.invalid && (type_add.dirty || type_add.touched) }"
                            required>
                            <option value="" disabled></option>
                            <option *ngFor="let item of typesArray" [ngValue]="item">{{item}}</option>
                        </select>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
                            <i class="fa fa-book" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="text-danger" *ngIf="type_add.invalid && (type_add.dirty || type_add.touched )">
                        <div>Este necesar tipul recomandării</div>
                    </div>

                    <!-- input textul recomandării -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="text_add" class="input100 form-control" type="text"
                            placeholder="Textul recomandării"
                            [ngClass]="{ 'is-invalid': text_add.invalid && (text_add.dirty || text_add.touched) }"
                            required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="text-danger" *ngIf="text_add.invalid && (text_add.dirty || text_add.touched )">
                        <div>Este necesar textul recomandării</div>
                    </div>

                    <div *ngIf="type_add.value === 'recomm'">
                        <!-- input imagine recomandării -->
                        <div class="wrap-input100 validate-input mt-2">
                            <input class="input100 form-control input100TypeFile" type="file"
                                (change)="onFileChanged($event)" required>
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <i class="fa fa-picture-o" aria-hidden="true"></i>
                            </span>
                        </div>
                        <p style="color:red; margin: 0;">*Nu se pot încărca poze mai mari de 2MB</p>
                        <p style="color:red;">*Înainte de a fi încărcate, pozele trebuie neaparat minifiate cu
                            <a href="https://tinypng.com" target="_blank">Tiny PNG</a>
                        </p>
                    </div>

                    <!-- {{ addForm.invalid }} -->
                    <button [disabled]="addForm.invalid" type="submit"
                        class="btn-default-dark d-block text-center mx-auto mt-4">
                        Trimite
                    </button>

                </form>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Închide</button>
                </div>
            </div>

        </div>
    </div>
</div>