<div class="template">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-md-12">
        <h2 class="mobileCenter mobileCenter-left mt-1">Contact</h2>
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="col-12">

        <form [formGroup]="editForm" (ngSubmit)="submitEdit()" class="login100-form validate-form">

          <!-- input name contact -->
          <div class="wrap-input100 validate-input">
            <input formControlName="name" class="input100 form-control" type="text"
              placeholder="Nume">
            <span class="focus-input100"></span>
            <span class="symbol-input100">
              <i class="fa fa-user" aria-hidden="true"></i>
            </span>
          </div>

          <!-- input phone contact -->
          <div class="wrap-input100 validate-input">
            <input formControlName="phone" class="input100 form-control" type="text"
              placeholder="Telefon">
            <span class="focus-input100"></span>
            <span class="symbol-input100">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </span>
          </div>

          <!-- input email contact -->
          <div class="wrap-input100 validate-input">
            <input formControlName="email" class="input100 form-control" type="text"
              placeholder="Email">
            <span class="focus-input100"></span>
            <span class="symbol-input100">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
            </span>
          </div>

          <!-- input location contact -->
          <div class="wrap-input100 validate-input">
            <input formControlName="location" class="input100 form-control" type="text"
              placeholder="Locație">
            <span class="focus-input100"></span>
            <span class="symbol-input100">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </span>
          </div>



          <button [disabled]="editForm.invalid" type="submit" class="btn-default-dark d-block text-center mx-auto mt-4">
            Trimite
          </button>

        </form>

      </div>
    </div>

  </div>
</div>
