<!-- Header Section Start -->
<header id="hero-area" data-stellar-background-ratio="0.5">
    <!-- Navbar Start -->
    <nav class="navbar navbar-expand-lg fixed-top scrolling-navbar indigo">
        <div class="container">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
                <a href="index.html" class="navbar-brand"><img class="img-fulid" src="assets/images/index/logo-5.png"
                        alt="Logo" width="65px"></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar"
                    aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="lnr lnr-menu"></i>
                </button>
            </div>
            <div class="collapse navbar-collapse" id="main-navbar">
                <ul class="navbar-nav mr-auto w-100 justify-content-end">
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="#hero-area">Acasă</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="#services">Despre noi</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="#features">Viziune</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="#blog">Creații</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="#portfolios">Fotografii</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll" href="#contact">Contact</a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Mobile Menu Start -->
        <ul class="mobile-menu">
            <li>
                <a class="page-scroll" href="#hero-area">Acasă</a>
            </li>
            <li>
                <a class="page-scroll" href="#services">Despre noi</a>
            </li>
            <li>
                <a class="page-scroll" href="#features">Viziune</a>
            </li>
            <li>
                <a class="page-scroll" href="#blog">Creații</a>
            </li>
            <li>
                <a class="page-scroll" href="#portfolios">Fotografii</a>
            </li>
            <li>
                <a class="page-scroll" href="#contact">Contact</a>
            </li>
        </ul>
        <!-- Mobile Menu End -->

    </nav>
    <!-- Navbar End -->
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="col-md-10">
                <div class="contents text-center">
                    <h1 class="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="0.3s">Organizația Studenților
                        Creștini Evanghelici din România Suceava</h1>
                    <!-- <p class="lead  wow fadeIn" data-wow-duration="1000ms" data-wow-delay="400ms">Motivați de dragostea lui Dumnezeu, grupul de studenți promovează principiile biblice în viața studențească prin ucenicizare</p> -->
                    <a class="btn btn-common wow fadeInUp comeWithUs" data-wow-duration="1000ms"
                        data-wow-delay="400ms">Hai cu noi !</a>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Header Section End -->

<!-- Services Section Start -->
<section id="services" class="section">
    <div class="container">
        <div class="section-header">
            <h2 class="section-title wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s">Despre noi</h2>
            <hr class="lines wow zoomIn" data-wow-delay="0.3s">
            <p class="section-subtitle wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s"> Motivați de
                dragostea lui Dumnezeu, grupul de studenți promovează principiile biblice în viața studențească prin
                ucenicizare.</p>
            <p class="section-subtitle wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s">Prin ucenicizare
                înțelegem dorința (căci atât ni se cuvine) de a crește în asemănarea cu Hristos. Din umila noastră
                dorință, Dumnezeu să fructifice, prin harul Său, rod spre slava și gloria Lui prin răspândirea Veștii
                Bune, indiferent de ce
                presupune asta.</p>
        </div>
        <!-- <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="item-boxes wow fadeInDown" data-wow-delay="0.2s">
              <div class="icon">
                <i class="lnr lnr-pencil"></i>
              </div>
              <h4>Content Writing</h4>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.</p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="item-boxes wow fadeInDown" data-wow-delay="0.8s">
              <div class="icon">
                <i class="lnr lnr-code"></i>
              </div>
              <h4>Web Development</h4>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.</p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="item-boxes wow fadeInDown" data-wow-delay="1.2s">
              <div class="icon">
                <i class="lnr lnr-mustache"></i>
              </div>
              <h4>Business Consultancy</h4>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut.</p>
            </div>
          </div>
        </div> -->
    </div>
</section>
<!-- Services Section End -->

<!-- Features Section Start -->
<section id="features" class="section" data-stellar-background-ratio="0.2">
    <div class="container">
        <div class="section-header">
            <h2 class="section-title">Viziune</h2>
            <hr class="lines">
            <p class="section-subtitle">Grupul își propune ca prin toate activitățile, indiferent de forma lor, să
                expună participanții la un context care să faciliteze conștientizarea nevoilor existente și soluționarea
                acestora din perspectivă biblică</p>
            <p class="section-subtitle"><i>Ne-am născut neajutorați. De îndată ce devenim pe deplin conștienți
                    descoperim singurătatea </i>, zicea C.S. Lewis. Ne alăturăm lui și consimțim cu nevoia de
                apartenență. <i>Fizic, emoțional, intelectual, avem nevoie de ceilalți, avem nevoie de ei
                    dacă e să cunoaștem ceva, chiar și pe noi înșine </i></p>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                            <span class="icon">
                                <i class="lnr lnr-calendar-full"></i>
                            </span>
                            <div class="text">
                                <h4>Întâlniri săptămânale</h4>
                                <p>În cadrul acestora abordăm subiecte care vizează conduita studentului creștin în
                                    mediul universitar</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                            <span class="icon">
                                <i class="lnr lnr-heart-pulse"></i>
                            </span>
                            <div class="text">
                                <h4>Activități sportive</h4>
                                <p>Ieșiri pe munte, volley, football, tabere de ski. Specificul acestora constă în
                                    construirea contextelor în care studenții au posibilitatea de a descoperi aspirații
                                    și neajunsuri comune</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                            <span class="icon">
                                <i class="lnr lnr-link"></i>
                            </span>
                            <div class="text">
                                <h4>Reflecții</h4>
                                <p>Mizeria și neputința umană odată conștientizate, cer a fi soluționate. Săptămânile de
                                    rugăciune și studiu biblic ne direcționează către soluție - Dumnezeu Însuși</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-12 box-item">
                            <span class="icon">
                                <i class="lnr lnr-users"></i>
                            </span>
                            <div class="text">
                                <h4>Comunitate</h4>
                                <p>Grupul vizează în special studenții, dar și liceenii sau absolvenții care sunt
                                    animați de aceeași dorință</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Features Section End -->

<!-- Blog Section -->
<section id="blog" class="section">
    <!-- Container Starts -->
    <div class="container-fluid px-5">
        <div class="section-header">
            <h2 class="section-title">Creații studențești</h2>
            <hr class="lines">
            <p class="section-subtitle">"Nici un om care prețuiește originalitatea nu va fi vreodată original.<br> În
                schimb, încercați să spuneți adevărul așa cum îl vedeți, încercați să faceți o treabă cât puteți de
                bine, de dragul muncii,<br> și ceea ce oamenii numesc originalitate va veni de la sine." <br> C.S. Lewis
            </p>
        </div>
        <div class="row">
            <div class="col-12 col-md-4" *ngFor="let item of posts | slice:0:3;">
                <div class="blog-item-wrapper wrapper">
                    <div class="blog-item-img"
                        style="background: url({{ environment.URLImages }}{{ item.image }}); background-size: cover;">
                    </div>
                    <div class="blog-item-text">
                        <div class="meta-tags">
                            <div class="row">
                                <div class="col-5">

                                    <span class="date"><i class="lnr  lnr-clock"></i> {{ item.daysDifference == 0 ?
                                        'Astăzi' : item.daysDifference == 1 ? 'Ieri': item.daysDifference + ' zile în
                                        urmă' }}</span>
                                </div>
                                <div class="col-7">
                                    <span class="date float-right"><i class="lnr lnr-pencil "></i>{{ item.author
                                        }}</span>
                                </div>
                            </div>
                        </div>
                        <h3>
                            <a (click)="blogPost(item.id)">{{ item.title }}</a>
                        </h3>
                        <p>{{ (item.content.length>250)? (item.content | slice:0:250)+'...':(item.content) }} </p>
                        <p (click)="blogPost(item.id)" class="btn-rm">Citește mai mult <i
                                class="lnr lnr-arrow-right"></i></p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="posts?.length > 3">
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-common mx-auto d-block mt-5" style="cursor: pointer;"
                        (click)="showAllPosts()">Vezi toate creațiile</button>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- blog Section End -->

<!-- Start Video promo Section -->
<section class="video-promo section">
    <div class="container" *ngIf="recomm?.length !== 0">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="video-promo-content text-center pb-4">
                    <h2 class="wow zoomIn" data-wow-duration="1000ms" data-wow-delay="100ms">Citate / Recomandări carte</h2>
                    <!-- <p class="wow zoomIn" data-wow-duration="1000ms" data-wow-delay="100ms">Aliquam vestibulum cursus felis. In iaculis iaculis sapien ac condimentum. Vestibulum congue posuere lacus, id tincidunt nisi porta sit amet. Suspendisse et sapien varius, pellentesque dui non, semper orci.</p> -->
                    <!-- <a href="https://www.youtube.com/watch?v=WkSY9cpU8EI" class="video-popup wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="0.3s"><i class="lnr lnr-film-play"></i></a> -->
                </div>
            </div>
            <div class="col-12 col-md-3" *ngFor="let item of recomm | slice:0:3;">
                <div *ngIf="item.type === 'quote'; else elseBlock">
                    <p>{{ (item.text.length>500)? (item.text |
                        slice:0:500)+'...':(item.text) }}</p>
                </div>
                <ng-template #elseBlock>
                    <div class="recomm">
                        <div class="recomm-image d-flex">
                            <img class="card-img-top" src="{{ environment.URLImages }}{{ item.image }}" alt="Card image cap">
                        </div>
                        <div class="recomm-text">
                            <h6 class="font-weight-bold text-center pt-4">{{item.text}} </h6>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</section>
<!-- End Video Promo Section -->

<!-- Portfolio Section -->
<section id="portfolios" class="section">
    <div class="container">
        <div class="section-header">
            <h2 class="section-title">Fotografii</h2>
            <hr class="lines">
            <p class="section-subtitle">"... orice concepție despre părtășia creștină care nu este în primul rând
                părtășie cu El nu are valoare." <br> C.S. Lewis</p>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <!-- iterator butoane -->
                <span *ngFor="let item of photosByYears | keyvalue">
                    <button class="btn btn-common mr-4 mt-2" style="cursor: pointer;"
                        (click)="displayPhotosOfYear(item.key)">{{ item.key }}</button>
                </span>
            </div>

            <div class="col-md-12 mt-5 text-center">
                <!-- iterator poze din anul respectiv -->
                <div *ngFor="let photo of displayedPhotos" class="col-md-4 photoImage mr-4"
                    style="background: url({{ environment.URLImages }}{{ photo.image }}); background-size: cover; background-position: 50% 50%;"
                    (click)="selectPhoto(photo)" data-toggle="modal" data-target="#photoModal">
                </div>
            </div>

            <!-- display photo modal -->
            <div id="photoModal" class="modal fade imageModal" role="dialog">
                <div class="modal-dialog">

                    <!-- Modal content-->
                    <div class="modal-content">
                        <div class="modal-header" style="padding: 5px; padding-right: 20px;">
                            <p class="modal-title"></p>
                            <button type="button" class="close" style="cursor: pointer;" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <img src="{{ environment.URLImages }}{{ enlargedPhoto?.image }}"
                                alt="Imagine OSCER {{enlargedPhoto?.year}} - {{enlargedPhoto?.id}}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Portfolio Section Ends -->

<!-- testimonial Section Start -->
<div id="testimonial" class="section">
    <div class="container-fluid px-5">
        <div class="section-header">
            <h2 class="section-title" style="color: white;">Evenimente studențești</h2>
            <hr class="lines">
            <!-- <p class="section-subtitle" style="color: white;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat, dignissimos! <br> Lorem ipsum dolor sit amet, consectetur.</p> -->
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 mt-3" *ngFor="let item of events | slice:0:3;">
                <div class="testimonial-item-wrapper wrapper">
                    <div class="testimonial-item-img"
                        style="background: url({{ environment.URLImages }}{{ item.image }}); background-size: cover;">
                    </div>
                    <div class="testimonial-item-text">
                        <div class="meta-tags">
                            <div class="row">
                                <div class="col-12">
                                    <span class="date"><i class="lnr  lnr-clock"></i> {{ item.date | date:'medium'
                                        }}</span>
                                </div>
                            </div>
                        </div>
                        <h3>
                            {{ item.title }}
                        </h3>
                        <p>{{ (item.info.length>400)? (item.info | slice:0:400)+'...':(item.info) }} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- testimonial Section Start -->

<!-- Services Section Start -->
<section id="utils" class="section">
    <div class="container-fluid">
        <div class="section-header">
            <h2 class="section-title wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s">Recomandări</h2>
            <hr class="lines wow zoomIn" data-wow-delay="0.3s">
            <div class="row mt-5">
                <div class="col-4 col-md-3">
                    <div class="recom-section">
                        <a href="https://www.usv.ro" target="_blank">
                            <div class="recom-photo">
                                <img src="assets/images/index/utils/logo-usv2.png" alt="logo-usv" width="50px">
                            </div>
                            <div class="recom-text">
                                <p class="section-subtitle wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s">
                                    Universitatea "Ștefan cel Mare"
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-4 col-md-3">
                    <div class="recom-section">
                        <a href="https://www.oscer.ro" target="_blank">
                            <div class="recom-photo">
                                <img src="assets/images/index/utils/oscer-national.png" alt="logo-oscer-national" width="150px">
                            </div>
                            <div class="recom-text">
                                <p class="section-subtitle wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s"> OSCER Național</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-4 col-md-3">
                    <div class="recom-section">
                        <a href="http://www.perlasuferintei.ro/" target="_blank">
                            <div class="recom-photo">
                                <img src="assets/images/index/utils/logo-perla-suferintei.jpg" alt="logo-perla-suferintei" width="50px">
                            </div>
                            <div class="recom-text">
                                <p class="section-subtitle wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s">
                                    Editura Perla Suferinței
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-4 col-md-3">
                    <div class="recom-section">
                        <a href="https://humanitas.ro" target="_blank">
                            <div class="recom-photo">
                                <img src="assets/images/index/utils/humanitas.png" alt="logo-csc-moldova" width="200px">
                            </div>
                            <div class="recom-text">
                                <p class="section-subtitle wow fadeIn" data-wow-duration="1000ms" data-wow-delay="0.3s">
                                    Editura HUMANITAS
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Services Section End -->

<!-- Contact Section Start -->
<section id="contact" class="section" data-stellar-background-ratio="-0.2">
    <div class="contact-form">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="contact-us d-block m-auto" style="width: 350px;">
                        <h3>Ia legătura cu noi</h3>
                        <div class="contact-address" *ngIf="contact">
                            <div class="row">
                                <div class="col-3 col-sm-4 pb-4" *ngIf="contact?.name">Nume</div>
                                <div class="col-9 col-sm-8 pb-4" *ngIf="contact?.name"><span> {{ contact?.name }}
                                    </span></div>

                                <div class="col-3 col-sm-4" *ngIf="contact?.phone">Telefon</div>
                                <div class="col-9 col-sm-8" *ngIf="contact?.phone">
                                    <p> <span> <a href="tel:{{ contact?.phone }}">{{ contact?.phone }}</a></span></p>
                                </div>

                                <div class="col-3 col-sm-4" *ngIf="contact?.email">Email</div>
                                <div class="col-9 col-sm-8" *ngIf="contact?.email">
                                    <p> <span> <a href="mailto:{{ contact?.email }}">{{ contact?.email }}</a></span></p>
                                </div>

                                <div class="col-3 col-sm-4" *ngIf="contact?.location">Locație</div>
                                <div class="col-9 col-sm-8" *ngIf="contact?.location"><span> {{ contact?.location }}
                                    </span></div>

                            </div>
                        </div>
                        <div class="social-icons">
                            <ul>
                                <li class="facebook"><a href="https://www.facebook.com/groups/267492099956878"
                                        target="_blank"><i class="fa fa-facebook"></i></a></li>
                                <li class="google-plus"><a href="mailto:{{ contact?.email }}" target="_blank"><i
                                            class="fa fa-google-plus"></i></a></li>
                                <li class="instagram"><a href="https://www.instagram.com/oscer_sv"
                                    target="_blank"><i class="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- Contact Section End -->

<!-- Footer Section Start -->
<app-footer></app-footer>
<!-- Footer Section End -->

<!-- Go To Top Link -->
<a href="#" class="back-to-top">
    <i class="lnr lnr-arrow-up"></i>
</a>

<div id="loader">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>