<div class="container">
  <div class="row">
    <div class="col-md-12 mb-5 mt-4">
      <p class="btn-rm mt-4 p-0" (click)="goBack()"><i class="lnr lnr-arrow-left"></i> Înapoi</p>
      <div style="overflow: hidden;">
        <img src="{{ environment?.URLImages }}{{ post?.image }}" class="d-block mx-auto mt-4" width="1140px" alt="Blog Image">
      </div>
      <div class="meta-tags mt-4">
        <div class="row">
          <div class="col-5">
            <span class="date"><i class="lnr  lnr-clock"></i> {{ post?.daysDifference == 0 ? 'Astăzi' : post?.daysDifference == 1 ? 'Ieri': post?.daysDifference + ' zile în urmă' }}</span>
          </div>
          <div class="col-7">
            <span class="date float-right"><i class="lnr lnr-pencil "></i>{{ post?.author }}</span>
          </div>
        </div>
      </div>

      <h3 class="text-bold mt-5">{{ post?.title }}</h3>
      <div class="mt-5" [innerHtml]="post?.content"></div>
    </div>
  </div>
</div>

<app-footer></app-footer>
