import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Contact } from 'src/app/_models/Contact';
import { ContactService } from 'src/app/_services/contacts.services';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  editForm: FormGroup;

  constructor(
    private contactService: ContactService,
    public toastr: ToastrManager,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.formInitializer();
    this.getContact();
  }

  formInitializer() {
    this.editForm = this.formBuilder.group({
      id: [],
      name: [''],
      phone: [''],
      email: [''],
      location: [''],
      created_at: [],
      updated_at: [],
    });
  }

  getContact() {
    this.contactService.getContacts().subscribe(
      (data) => {
        this.editForm.setValue(data[0]);
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Contactul nu au putut fi preluat! '
        );
      }
    );
  }

  get id() {
    return this.editForm.get('id');
  }

  get name() {
    return this.editForm.get('name');
  }

  get phone() {
    return this.editForm.get('phone');
  }

  get email() {
    return this.editForm.get('email');
  }

  submitEdit() {
    this.contactService.editContact(this.editForm.value).subscribe(
      () => {
        this.getContact();
        this.toastr.successToastr('A fost editat cu success! ');
      },
      (error) => {
        this.toastr.errorToastr(error.message, 'Nu a putut fi modificat! ');
      }
    );
  }
}
