import { Injectable } from '@angular/core';
import { Contact } from '../_models/Contact';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient) {}

  getContacts() {
    return this.http.get<Contact[]>(environment.URL + 'contact');
  }

  getContact(id: number) {
    return this.http.get<Contact>(environment.URL + 'contact/' + id);
  }

  createContact(contact: Contact) {
    return this.http.post(environment.URL + 'contact', contact);
  }

  deleteContact(contactID: number) {
    return this.http.delete(environment.URL + 'contact/' + contactID);
  }

  editContact(contact: Contact) {
    return this.http.put(environment.URL + 'contact/' + contact.id, contact);
  }
}
