import { Component, OnInit } from '@angular/core';

import { Post } from 'src/app/_models/Post';
import { PostService } from 'src/app/_services/posts.service';
import { environment } from 'src/environments/environment';

import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.css'],
})
export class BlogPostsComponent implements OnInit {
  posts: Post[];
  environment;

  constructor(
    private router: Router,
    private postService: PostService,
    public toastr: ToastrManager
  ) {}

  ngOnInit(): void {
    this.environment = environment;
    this.getPosts();
  }

  getPosts() {
    this.postService.getPostsIndex().subscribe(
      (data) => {
        this.posts = data.reverse();
        this.posts.forEach((element) => {
          // remove html elements
          element.content = element.content.replace(/<(.|\n)*?>/g, ' ');
          // remove new line
          element.content = element.content.replace('&nbsp;', ' ');
          // calculate the days difference between post date and today
          element.daysDifference = Math.round(
            (new Date().getTime() - new Date(element.created_at).getTime()) /
              (1000 * 3600 * 24)
          );
        });
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Creațiile studențești nu au putut fi preluate! '
        );
      }
    );
  }

  blogPost(blogID) {
    this.router.navigate(['blog-post/', 2, blogID]);
  }

  goBack(){
    this.router.navigate(['index']);
  }
}
