import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Event } from 'src/app/_models/Event';
import { EventService } from 'src/app/_services/events.service';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
})
export class EventsComponent implements OnInit {
  AppComponent = new AppComponent(this.router);
  environment;

  events: Event[];
  term: string;
  editImagePath: string;

  itemPerPage = 5;
  curentPage = 1;

  @ViewChild('editModal') private editModal: ElementRef;
  @ViewChild('addModal') private addModal: ElementRef;
  editForm: FormGroup;
  addForm: FormGroup;

  constructor(
    private router: Router,
    private eventService: EventService,
    public toastr: ToastrManager,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.AppComponent.checkAuth();
    this.environment = environment;
    this.formsInitializer();
    this.getEvents();
  }

  formsInitializer() {
    this.editForm = this.formBuilder.group({
      id: [],
      created_at: [],
      updated_at: [],
      title: ['', Validators.required],
      date: ['', Validators.required],
      info: ['', Validators.required],
      image: [''],
    });
    this.addForm = this.formBuilder.group({
      id_add: [],
      created_at_add: [],
      updated_at_add: [],
      title_add: ['', Validators.required],
      date_add: ['', Validators.required],
      info_add: ['', Validators.required],
      image_add: ['',  Validators.required],
    });
  }

  getEvents() {
    this.eventService.getEvents().subscribe(
      (data) => {
        this.events = data.reverse();
        console.log(this.events);
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Evenimentele nu au putut fi preluate! '
        );
      }
    );
  }

  deleteEvent(eventID) {
    this.eventService.deleteEvent(eventID).subscribe(
      () => {
        this.getEvents();
        this.toastr.successToastr('Evenimentul a fost ștears din listă! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Evenimentul NU a putut fi ștears!'
        );
      }
    );
  }

  selectForEdit(event: Event) {
    this.editForm.setValue(event);
    this.editImagePath =  event.image;
  }

  submitEdit() { 
    this.eventService.editEvent(this.editForm.value).subscribe(
      () => {
        this.getEvents();
        this.editModal.nativeElement.click();
        this.toastr.successToastr('Evenimentul a fost editat cu success! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Evenimentul nu au putut fi modificat! '
        );
      }
    );
  }

  submitAdd() {
    const formData = new FormData();
    formData.append('image', this.addForm.get('image_add').value);
    formData.append('title', this.addForm.get('title_add').value);
    formData.append('info', this.addForm.get('info_add').value);
    formData.append('date', this.addForm.get('date_add').value);

    this.eventService.addEvent(formData).subscribe(
      () => {
        this.getEvents();
        this.addModal.nativeElement.click();
        this.toastr.successToastr('Evenimentul a fost adăugat cu success! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Evenimentul nu au putut fi adăugat! '
        );
      }
    );
  }

  onFileChanged(event: any) {
    this.addForm.get('image_add').setValue(event.target.files[0]);
  }

  get id() {
    return this.editForm.get('id');
  }

  get title() {
    return this.editForm.get('title');
  }

  get date() {
    return this.editForm.get('date');
  }

  get info() {
    return this.editForm.get('info');
  }

  get image() {
    return this.editForm.get('image');
  }

  get id_add() {
    return this.addForm.get('id_add');
  }

  get title_add() {
    return this.addForm.get('title_add');
  }

  get info_add() {
    return this.addForm.get('info_add');
  }

  get date_add() {
    return this.addForm.get('date_add');
  }

  get image_add() {
    return this.addForm.get('image_add');
  }

}
