import { Injectable } from '@angular/core';
import { PhotoByYear } from '../_models/PhotoByYear';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PhotoByYearsService {
  constructor(private http: HttpClient) {}

  getPhotosByYears() {
    return this.http.get<PhotoByYear[]>(environment.URL + 'photobyyears');
  }

  getPhotoByYears(id: number) {
    return this.http.get<PhotoByYear>(environment.URL + 'photobyyears/' + id);
  }

  deletePhotoByYears(photoByYearsID: number) {
    return this.http.delete(environment.URL + 'photobyyears/' + photoByYearsID);
  }

  editPhotoByYears(photoByYears: PhotoByYear) {
    return this.http.put(environment.URL + 'photobyyears/' + photoByYears.id, photoByYears);
  }

  addPhotoByYears(photoByYears: FormData) {
    return this.http.post(environment.URL + 'photobyyears', photoByYears);
  }

  //unauthentificated
  getPhotoByYearsIndex() {
    return this.http.get<PhotoByYear[]>(environment.URL + 'getPhotoByYears');
  }
}
