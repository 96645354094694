import { Injectable } from '@angular/core';
import { Recomm } from '../_models/Recomm';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecommService {
  constructor(private http: HttpClient) {}

  getRecomms() {
    return this.http.get<Recomm[]>(environment.URL + 'recomm');
  }

  getRecomm(id: number) {
    return this.http.get<Recomm>(environment.URL + 'recomm/' + id);
  }

  deleteRecomm(postID: number) {
    return this.http.delete(environment.URL + 'recomm/' + postID);
  }

  editRecomm(post: Recomm) {
    return this.http.put(environment.URL + 'recomm/' + post.id, post);
  }

  addRecomm(post: FormData) {
    return this.http.post(environment.URL + 'recomm', post);
  }

  //unauthentificated
  getRecommsIndex() {
    return this.http.get<Recomm[]>(environment.URL + 'getRecomm');
  }
}
