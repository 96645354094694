import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { EditorModule } from '@tinymce/tinymce-angular';

import { TokenInterceptor } from './_services/token-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { LoginComponent } from './admin/login/login.component';
import { AdminComponent } from './admin/layout/admin-pannel/admin.component';
import { AdminHeaderComponent } from './admin/layout/admin-header/admin-header.component';
import { BlogPostComponent } from './index/blog-post/blog-post.component';
import { FooterComponent } from './index/footer/footer.component';
import { PostsComponent } from './admin/components/posts/posts/posts.component';
import { BlogPostsComponent } from './index/blog-posts/blog-posts.component';
import { ContactComponent } from './admin/components/contact/contact/contact.component';
import { EventsComponent } from './admin/components/events/events/events.component';
import { PhotoByYearsComponent } from './admin/components/photoByYears/photo-by-years/photo-by-years.component';
import { RecommComponent } from './admin/components/recomm/recomm/recomm.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    LoginComponent,
    AdminComponent,
    AdminHeaderComponent,
    BlogPostComponent,
    FooterComponent,
    PostsComponent,
    BlogPostsComponent,
    ContactComponent,
    EventsComponent,
    PhotoByYearsComponent,
    RecommComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    NgbModalModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    EditorModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
