import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { PhotoByYearsService } from 'src/app/_services/photoByYears';
import { PhotoByYear } from 'src/app/_models/PhotoByYear';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-photo-by-years',
  templateUrl: './photo-by-years.component.html',
  styleUrls: ['./photo-by-years.component.css'],
})
export class PhotoByYearsComponent implements OnInit {
  AppComponent = new AppComponent(this.router);
  environment;

  photosByYears: PhotoByYear[];
  term: string;
  yearsArray: [Number] = [2000];

  itemPerPage = 20;
  curentPage = 1;

  @ViewChild('editModal') private editModal: ElementRef;
  @ViewChild('addModal') private addModal: ElementRef;
  editForm: FormGroup;
  addForm: FormGroup;

  constructor(
    private router: Router,
    private photoByYearsService: PhotoByYearsService,
    public toastr: ToastrManager,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.AppComponent.checkAuth();
    this.environment = environment;
    this.formsInitializer();
    this.yearsInitializer();
    this.getPhotoByYears();
  }

  formsInitializer() {
    this.editForm = this.formBuilder.group({
      id: [],
      created_at: [],
      updated_at: [],
      year: ['', Validators.required],
      image: [''],
    });
    this.addForm = this.formBuilder.group({
      id_add: [],
      created_at_add: [],
      updated_at_add: [],
      year_add: ['', Validators.required],
      image_add: ['', Validators.required],
    });
  }

  yearsInitializer() {
    for (let index = 2001; index <= 2050; index++) {
      this.yearsArray.push(index);
    }
  }

  getPhotoByYears() {
    this.photoByYearsService.getPhotosByYears().subscribe(
      (data) => {
        this.photosByYears = data.sort((a, b) => a.year > b.year ? -1 : a.year < b.year ? 1 : 0);
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Pozele nu au putut fi preluate! '
        );
      }
    );
  }

  deletePhotoByYear(photoByYearID) {
    this.photoByYearsService.deletePhotoByYears(photoByYearID).subscribe(
      () => {
        this.getPhotoByYears();
        this.toastr.successToastr('Poza a fost ștearsă din listă! ');
      },
      (error) => {
        this.toastr.errorToastr(error.message, 'Poza NU a putut fi ștearsă!');
      }
    );
  }

  submitEdit() {
    this.photoByYearsService.editPhotoByYears(this.editForm.value).subscribe(
      () => {
        this.getPhotoByYears();
        this.editModal.nativeElement.click();
        this.toastr.successToastr('Poza a fost editată cu success! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Poza NU au putut fi modificată! '
        );
      }
    );
  }

  submitAdd() {
    const formData = new FormData();
    formData.append('image', this.addForm.get('image_add').value);
    formData.append('year', this.addForm.get('year_add').value);

    this.photoByYearsService.addPhotoByYears(formData).subscribe(
      () => {
        this.getPhotoByYears();
        this.addModal.nativeElement.click();
        this.toastr.successToastr('Poza a fost adăugată cu success! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Poza nu au putut fi adăugată! '
        );
      }
    );
  }

  selectForEdit(photoByYear: PhotoByYear) {
    this.editForm.setValue(photoByYear);
  }

  onFileChanged(photoByYear: any) {
    this.addForm.get('image_add').setValue(photoByYear.target.files[0]);
  }

  get id() {
    return this.editForm.get('id');
  }

  get year() {
    return this.editForm.get('year');
  }

  get image() {
    return this.editForm.get('image');
  }

  get id_add() {
    return this.addForm.get('id_add');
  }

  get year_add() {
    return this.addForm.get('year_add');
  }

  get image_add() {
    return this.addForm.get('image_add');
  }
}
