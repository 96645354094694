import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';
import { LoginResponse, User } from 'src/app/_models/User';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    public toastr: ToastrManager
  ) {}

  user: User = new User();
  loading = false;

  ngOnInit() {}

  login() {
    this.loading = true;
    this.http.post(environment.URL + 'login', this.user).subscribe(
      (response: LoginResponse) => {
        if (!response.access_token) {
          this.toastr.errorToastr(response.message);
          sessionStorage.setItem('token', 'false');
        } else {
          sessionStorage.setItem('access_token', response.access_token);
          sessionStorage.setItem('user', response.name);
          sessionStorage.setItem('token', 'true');
          this.toastr.successToastr('Bun venit ' + response.name + '!');
          this.router.navigate(['/admin']);
        }
      },
      (error) => {
        this.toastr.errorToastr(error.message, error.statusText);
      }
    );
    this.loading = false;
  }
}
