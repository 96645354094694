import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AppComponent } from 'src/app/app.component';
import { ToastrManager } from 'ng6-toastr-notifications';

import { Recomm } from 'src/app/_models/Recomm';
import { RecommService } from 'src/app/_services/recomms.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recomm',
  templateUrl: './recomm.component.html',
  styleUrls: ['./recomm.component.css']
})
export class RecommComponent implements OnInit {

  AppComponent = new AppComponent(this.router);

  recomms: Recomm[];
  term: string;

  typesArray: [String, String] = ["quote", "recomm"];


  @ViewChild('editModal') private editModal: ElementRef;
  @ViewChild('addModal') private addModal: ElementRef;
  editForm: FormGroup;
  addForm: FormGroup;

  environment;

  itemPerPage = 5;
  curentPage = 1;

  editImagePath: string;
  postContent: any;

  constructor(
    private router: Router,
    private recommService: RecommService,
    public toastr: ToastrManager,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.AppComponent.checkAuth();
    this.environment = environment;
    this.formsInitializer();
    this.getRecomms();
  }

  formsInitializer() {
    this.editForm = this.formBuilder.group({
      id: [],
      created_at: [],
      updated_at: [],
      type: ['', Validators.required],
      text: ['', Validators.required],
      image: [''],
    });
    this.addForm = this.formBuilder.group({
      id_add: [],
      created_at_add: [],
      updated_at_add: [],
      type_add: ['', Validators.required],
      text_add: ['', Validators.required],
      image_add: [''],
    });
  }

  getRecomms() {
    this.recommService.getRecomms().subscribe(
      (data) => {
        this.recomms = data.reverse();
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Recomandările nu au putut fi preluate! '
        );
      }
    );
  }

  get id() {
    return this.editForm.get('id');
  }

  get type() {
    return this.editForm.get('type');
  }

  get text() {
    return this.editForm.get('text');
  }

  get image() {
    return this.editForm.get('image');
  }

  get id_add() {
    return this.addForm.get('id_add');
  }

  get type_add() {
    return this.addForm.get('type_add');
  }

  get text_add() {
    return this.addForm.get('text_add');
  }

  get image_add() {
    return this.addForm.get('image_add');
  }

  selectForEdit(recomm: Recomm) {
    this.editForm.setValue(recomm);
    this.editImagePath =  recomm.image;
  }
  
  submitEdit() { 
    this.recommService.editRecomm(this.editForm.value).subscribe(
      () => {
        this.getRecomms();
        this.editModal.nativeElement.click();
        this.toastr.successToastr('Recomandarea a fost editată cu success! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Recomandarea nu au putut fi modificată! '
        );
      }
    );
  }

  submitAdd() {
    const formData = new FormData();
    formData.append('image', this.addForm.get('image_add').value);
    formData.append('type', this.addForm.get('type_add').value);
    formData.append('text', this.addForm.get('text_add').value);

    console.log(formData.getAll);
    

    this.recommService.addRecomm(formData).subscribe(
      () => {
        this.getRecomms();
        this.addModal.nativeElement.click();
        this.toastr.successToastr('Recomandarea a fost adăugată cu success! ');
      },
      (error) => {
        this.toastr.errorToastr(
          "Verificați vă rog dacă imaginea a fost încărcată",
          'Recomandarea nu au putut fi adăugată! '
        );
      }
    );
  }

  onFileChanged(event: any) {
    this.addForm.get('image_add').setValue(event.target.files[0]);
  }

  deletePost(recommID) {
    this.recommService.deleteRecomm(recommID).subscribe(
      () => {
        this.getRecomms();
        this.toastr.successToastr('Recomandarea a fost ștearsă din listă! ');
      },
      (error) => {
        this.toastr.errorToastr(
          error.message,
          'Recomandarea NU a putut fi ștearsă!'
        );
      }
    );
  }

}
