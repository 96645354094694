<div class="template">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <h2 class="mobileCenter mobileCenter-left mt-1">Creații studențești</h2>
            </div>
            <div class="col-xs-12 col-md-6">
                <button class="btn-default-dark mobileCenter mobileCenter-right" data-toggle="modal" data-target="#addModal">
          <i class="fa fa-plus mr-2" aria-hidden="true"></i> Adaugă creație
        </button>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control mt-3 mb-4" [(ngModel)]="term" style="border:1px solid #ced4da;" placeholder="Căutare">
            </div>
            <div class="col-12">
                <div class="table-responsive" *ngIf="posts">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">ID</th>
                                <th scope="col">Titlu</th>
                                <th scope="col">Autor</th>
                                <th scope="col">Conținut</th>
                                <th scope="col">Imagine</th>
                                <th scope="col">Creat</th>
                                <th scope="col">Updatat</th>
                                <th scope="col">Optiuni</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of posts | filter:term | paginate: { itemsPerPage: itemPerPage, currentPage: curentPage }">
                                <td class="align-middle text-center">{{ item.id }}</td>
                                <td class="align-middle text-center">{{ item.title }}</td>
                                <td class="align-middle text-center">{{ item.author }}</td>
                                <td class="align-middle text-center">{{ (item.content.length>250)? (item.content | slice:0:250)+'..':(item.content) }}</td>
                                <td class="align-middle text-center">
                                    <img src='{{ environment.URLImages }}{{ item.image }}' alt="Imagine Creații studențești" width="150px">
                                </td>
                                <td class="align-middle text-center">{{ item.updated_at | date:'medium' }} </td>
                                <td class="align-middle text-center">{{ item.created_at | date:'medium' }}</td>

                                <td class="align-middle text-center options">
                                    <i class="fa fa-edit" (click)="selectForEdit(item)" data-toggle="modal" data-target="#editModal"></i>
                                    <i class="fa fa-trash" (click)="deletePost(item.id)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls autoHide="true" class="text-center" (pageChange)="curentPage = $event">
                    </pagination-controls>
                </div>
            </div>
        </div>

    </div>
</div>



<!-- post to edit modal -->

<!-- Modal -->
<div id="editModal" #editModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modifică creație</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="editForm" (ngSubmit)="submitEdit()" class="login100-form validate-form">

                    <!-- input titlul creației -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="title" class="input100 form-control" type="text" placeholder="Titlul creației" [ngClass]="{ 'is-invalid': title.invalid && (title.dirty || title.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-book" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="title.invalid && (title.dirty || title.touched )">
                        <div *ngIf="title.errors['required']">Este necesar titlul creației</div>
                    </div>

                    <!-- input author creației -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="author" class="input100 form-control" type="text" placeholder="Autorul creației" [ngClass]="{ 'is-invalid': author.invalid && (author.dirty || author.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="author.invalid && (author.dirty || author.touched )">
                        <div *ngIf="author.errors['required']">Este necesar autorul creației</div>
                    </div>

                    <!-- input content creației -->
                    <editor formControlName="content" [init]="{height: 500}" apiKey="ucn13ec3vepex85r5o3awadcrzfze1kh32qan9acwlgxtgfy"> </editor>
                    <div class="text-danger" *ngIf="content.invalid && (content.dirty || content.touched )">
                        <div>Este necesar conținutul creației</div>
                    </div>

                    <button [disabled]="editForm.invalid" type="submit" class="btn-default-dark d-block text-center mx-auto mt-4">
            Trimite
          </button>

                </form>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Închide</button>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- post to add modal -->

<!-- Modal -->
<div id="addModal" #addModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Adaugă creație</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="addForm" (ngSubmit)="submitAdd()" class="login100-form validate-form">
                    <!-- input titlul creației -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="title_add" class="input100 form-control" type="text" placeholder="Titlul creației" [ngClass]="{ 'is-invalid': title_add.invalid && (title_add.dirty || title_add.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-book" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="title_add.invalid && (title_add.dirty || title_add.touched )">
                        <div>Este necesar titlul creației</div>
                    </div>

                    <!-- input author creației -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="author_add" class="input100 form-control" type="text" placeholder="Autorul creației" [ngClass]="{ 'is-invalid': author_add.invalid && (author_add.dirty || author_add.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-user" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="author_add.invalid && (author_add.dirty || author_add.touched )">
                        <div>Este necesar autorul creației</div>
                    </div>

                    <!-- input content creației -->
                    <editor formControlName="content_add" [init]="{height: 500}" apiKey="ucn13ec3vepex85r5o3awadcrzfze1kh32qan9acwlgxtgfy"> </editor>
                    <div class="text-danger" *ngIf="content_add.invalid && (content_add.dirty || content_add.touched )">
                        <div>Este necesar conținutul creației</div>
                    </div>

                    <!-- input imagine creației -->
                    <div class="wrap-input100 validate-input mt-2">
                        <input class="input100 form-control input100TypeFile" type="file" (change)="onFileChanged($event)" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-picture-o" aria-hidden="true"></i>
            </span>
                    </div>
                    <p style="color:red; margin: 0;">*Nu se pot încărca poze mai mari de 2MB</p>
                    <p style="color:red;">*Înainte de a fi încărcate, pozele trebuie neaparat minifiate cu
                        <a href="https://tinypng.com" target="_blank">Tiny PNG</a></p>

                    <!-- {{ addForm.invalid }} -->
                    <button [disabled]="addForm.invalid" type="submit" class="btn-default-dark d-block text-center mx-auto mt-4">
            Trimite
          </button>

                </form>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Închide</button>
                </div>
            </div>

        </div>
    </div>
</div>