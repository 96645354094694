<div class="template">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12 col-md-6">
                <h2 class="mobileCenter mobileCenter-left mt-1">Evenimente</h2>
            </div>
            <div class="col-xs-12 col-md-6">
                <button class="btn-default-dark mobileCenter mobileCenter-right" data-toggle="modal" data-target="#addModal">
          <i class="fa fa-plus mr-2" aria-hidden="true"></i> Adaugă eveniment
        </button>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-12">
                <input type="text" class="form-control mt-3 mb-4" [(ngModel)]="term" style="border:1px solid #ced4da;" placeholder="Căutare">
            </div>
            <div class="col-12">
                <div class="table-responsive" *ngIf="events">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">ID</th>
                                <th scope="col">Titlu</th>
                                <th scope="col">Dată</th>
                                <th scope="col">Informații</th>
                                <th scope="col">Imagine</th>
                                <th scope="col">Creat</th>
                                <th scope="col">Updatat</th>
                                <th scope="col">Optiuni</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of events | filter:term | paginate: { itemsPerPage: itemPerPage, currentPage: curentPage }">
                                <td class="align-middle text-center">{{ item.id }}</td>
                                <td class="align-middle text-center">{{ item.title }}</td>
                                <td class="align-middle text-center">{{ item.date }}</td>
                                <td class="align-middle text-center">{{ (item.info.length>250)? (item.info | slice:0:250)+'..':(item.info) }}</td>
                                <td class="align-middle text-center">
                                    <img src='{{ environment.URLImages }}{{ item.image }}' alt="Imagine Eveniment" width="150px">
                                </td>
                                <td class="align-middle text-center">{{ item.updated_at | date:'medium' }} </td>
                                <td class="align-middle text-center">{{ item.created_at | date:'medium' }}</td>

                                <td class="align-middle text-center options">
                                    <i class="fa fa-edit" (click)="selectForEdit(item)" data-toggle="modal" data-target="#editModal"></i>
                                    <i class="fa fa-trash" (click)="deleteEvent(item.id)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls autoHide="true" class="text-center" (pageChange)="curentPage = $event">
                    </pagination-controls>
                </div>
            </div>
        </div>

    </div>
</div>


<!-- event to edit modal -->

<!-- Modal -->
<div id="editModal" #editModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modifică evenimentul</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="editForm" (ngSubmit)="submitEdit()" class="login100-form validate-form">

                    <!-- input titlul evenimentului -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="title" class="input100 form-control" type="text" placeholder="Titlul evenimentului" [ngClass]="{ 'is-invalid': title.invalid && (title.dirty || title.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="title.invalid && (title.dirty || title.touched )">
                        <div *ngIf="title.errors['required']">Este necesar titlul evenimentului</div>
                    </div>

                    <!-- input info evenimentului -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="info" class="input100 form-control" type="text" placeholder="Autorul evenimentului" [ngClass]="{ 'is-invalid': info.invalid && (info.dirty || info.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-american-sign-language-interpreting" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="info.invalid && (info.dirty || info.touched )">
                        <div *ngIf="info.errors['required']">Sunt necesare Informațiile evenimentului</div>
                    </div>

                    <!-- input data evenimentului -->
                    <input type="datetime-local" formControlName="date" style="margin: auto; display: block; margin-top: 2rem;">

                    <button [disabled]="editForm.invalid" type="submit" class="btn-default-dark d-block text-center mx-auto mt-4">
            Trimite
          </button>

                </form>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Închide</button>
                </div>
            </div>

        </div>
    </div>
</div>



<!-- event to add modal -->

<!-- Modal -->
<div id="addModal" #addModal class="modal fade" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Adaugă evenimentul</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="addForm" (ngSubmit)="submitAdd()" class="login100-form validate-form">

                    <!-- input titlul evenimentului -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="title_add" class="input100 form-control" type="text" placeholder="Titlul evenimentului" [ngClass]="{ 'is-invalid': title_add.invalid && (title_add.dirty || title_add.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="title_add.invalid && (title_add.dirty || title_add.touched )">
                        <div *ngIf="title_add.errors['required']">Este necesar titlul evenimentului</div>
                    </div>

                    <!-- input info evenimentului -->
                    <div class="wrap-input100 validate-input">
                        <input formControlName="info_add" class="input100 form-control" type="text" placeholder="Autorul evenimentului" [ngClass]="{ 'is-invalid': info_add.invalid && (info_add.dirty || info_add.touched) }" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-american-sign-language-interpreting" aria-hidden="true"></i>
            </span>
                    </div>
                    <div class="text-danger" *ngIf="info_add.invalid && (info_add.dirty || info_add.touched )">
                        <div *ngIf="info_add.errors['required']">Sunt necesare Informațiile evenimentului</div>
                    </div>

                    <!-- input imagine creației -->
                    <div class="wrap-input100 validate-input mt-2">
                        <input class="input100 form-control input100TypeFile" type="file" (change)="onFileChanged($event)" required>
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
              <i class="fa fa-picture-o" aria-hidden="true"></i>
            </span>
                    </div>
                    <p style="color:red; margin: 0;">*Nu se pot încărca poze mai mari de 2MB</p>
                    <p style="color:red;">*Înainte de a fi încărcate, pozele trebuie neaparat minifiate cu
                        <a href="https://tinypng.com" target="_blank">Tiny PNG</a></p>

                    <!-- input data evenimentului -->
                    <input type="datetime-local" formControlName="date_add" style="margin: auto; display: block; margin-top: 2rem;">

                    <!-- {{ addForm.invalid }} -->
                    <button [disabled]="addForm.invalid" type="submit" class="btn-default-dark d-block text-center mx-auto mt-4">
            Trimite
          </button>

                </form>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button type="button" class="btn-default-dark" data-dismiss="modal">Închide</button>
                </div>
            </div>

        </div>
    </div>
</div>